<template>
<v-container lighten-5>
    <v-row>
        <v-col cols="auto" md="12">
             <v-card elevation="0" class="rounded-xl">
                <v-data-iterator :items="lista"  :loading="loadingTable" :item-key="tabla.itemKey" :items-per-page="50" hide-default-footer :sort-by="tabla.orderBy" :sort-desc="tabla.orderDesc">
                    <template v-slot:header>
                        <v-toolbar dark class="mb-1">
                            <v-btn class="mx-2 primary--text" tile color="white" @click.stop="dialog = true">
                                agregar
                            </v-btn>
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <div class="flex-grow-1"></div>
                        </v-toolbar>
                    </template>
                    <template v-slot:default="{ items, isExpanded, expand }">
                        <CardTable v-bind:table="{ tabla, data:{ items, isExpanded, expand }}"></CardTable>
                    </template>
                    <template v-slot:no-data>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="10" sm="10" md="10" lg="10">
                                <v-alert text dense color="primary" icon="mdi-database-search" border="left">
                                    No se encontro nada.
                                </v-alert>
                            </v-col>
                        </v-row>
                    </template>
                    <template v-slot:loading>
                        <v-row class="mt-2" align="center" justify="center">
                            <v-col cols="12" sm="12" md="12" lg="12">
                                <Loader />
                            </v-col>
                        </v-row>
                    </template>
                </v-data-iterator>
            </v-card>
        </v-col>
    </v-row>

    <v-form @submit.prevent="agregar">
        <v-dialog v-model="dialog" width="800px" max-width="800px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="12">
                                <v-autocomplete outlined  required :error="$v.form.coseId.$error" v-model="form.coseId" :items="listaServicios" item-text="coseNombre" item-value="coseId" item-color="primary" label="Servicio" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="12" v-if="form.cups">
                                <v-autocomplete outlined :error="$v.form.cocuId.$error" v-if="form.cups.cups" required v-model="form.cocuId" :loading="form.cups.isLoading" :search-input.sync="form.cups.search" hide-no-data hide-selected no-filter  :items="form.cups.cups" @keypress.enter="searchCup()" item-text="cocuNombre" item-value="cocuId" item-color="primary" label="Cup" clearable>
                                </v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete outlined :error="$v.form.coseTipoServicio.$error" v-model="form.coseTipoServicio" :items="tiposervicio" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Tipo de Servicio" clearable></v-autocomplete>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-autocomplete outlined  required :error="$v.form.cpesTipoEstado.$error" v-model="form.cpesTipoEstado" :items="tipoEstado" item-text="comaNombrelargo" item-value="comaId" item-color="primary" label="Estado" clearable></v-autocomplete>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <div class="flex-grow-1"></div>
                    <v-btn color="primary darken-1" text @click="close">Cancelar</v-btn>
                    <v-btn color="primary darken-1" dark @click="agregar">Guardar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-form>
</v-container>
</template>

<script>
import {
    required
}
from 'vuelidate/lib/validators';
import generales from '../../../mixins/generales.js';
export default {
     mixins: [generales],
    name: "Servicios",
    props: {
        coprId: Number,
    },
    data() {
        return {
            url: "sistema/Personasempleadosservicios/",
            nameRules: [v => !!v || "Campo requirido"],
            form: {
                cpesId: null,
                coprId: null,
                coseId: null,
                cocuId:null,
                coseTipoServicio:null,
                cpesTipoEstado: null,
                cups :{
                  search: null,
                  isLoading: false,
                  cups: []
                }
            },
            tabla: {
                itemKey: 'cpesId',
                orderBy: 'cpesId',
                orderDesc: true,
                expandible: false,
                expandibleKey: 'cpesId',
                expandibleTitle: () => { return `` },
                expandibleItems: () => { return [] },
                primarios: [{
                        titulo: 'SERVICIO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'primary--text subtitle-1',
                        valor: (item) => { return `${item.tblConfServicio.coseNombre}` },
                        show: (item) => { return item ? true : false }
                    }, {
                        titulo: 'CUPS',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '12',
                            lg: '12'
                        },
                        class: 'green--text subtitle-1',
                        valor: (item) => { return item.tblConfCup?item.tblConfCup.cocuNombre:'' },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'TIPO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.tipo ? item.tipo.comaNombrelargo:'' },
                        show: (item) => { return item ? true : false }
                    },
                    {
                        titulo: 'ESTADO',
                        tamaño: {
                            cols: '12',
                            sm: '12',
                            md: '3',
                            lg: '3'
                        },
                        class: 'primary--text',
                        valor: (item) => { return item.estado? item.estado.comaNombrecorto :''},
                        show: (item) => { return item ? true : false }
                    },

                ],
                segundarios: [],
                expanItem: [],
                expanMenu: [],
                menu: [
                    {
                        title: 'Editar',
                        icon: 'create',
                        color: 'info',
                        tipeAction: 'funcion',
                        action: (item) => { this.dialog = true; return this.editar(item.cpesId)},
                        show: (item) => { return item ? true : false  }
                    },
                    {
                        title: 'Eliminar',
                        icon: 'delete',
                        color: 'error',
                        tipeAction: 'funcion',
                        action: (item) => { this.eliminar(item.cpesId) },
                        show: (item) => { return item ? true : false }
                    },
                ]
            },
            listaServicios: [],
            tipoEstado: [],
            tiposervicio:[]
        };
    },
    validations: {
        form: {
            coprId: {
                required,
            },
            coseId: {
                required,
            },
            cocuId: {
                required,
            },
            coseTipoServicio: {
                required,
            },
            cpesTipoEstado: {
                required,
            },

        },

    },
    computed: {
        formTitle() {
            return this.isNew === true ? "Nuevo" : "Actualizar";
        },
    },
    watch: {
        dialog(val) {
            val || this.close();
        }
    },
    created: async function () {
        this.loadingTable = true;
        this.lista = await this.$apiService.index(this.url + "index/" + this.coprId).then(data => data);
        this.loadingTable = false;
        this.form.coprId = this.coprId;
        this.listaServicios = await this.$apiService.index("sistema/servicios/index").then(data => data);
        this.tiposervicio = await this.$apiService.index("sistema/maestra/index/TBL_TIPOSERVICIOS").then(data => data);
        this.tipoEstado = await this.$apiService.index("sistema/maestra/index/TBL_TIPOESTADO").then(data => data);
        this.limpiar();
    },

    methods: {
        async searchCup() {
            this.form.cups.isLoading = true;
            this.form.cups.cups = [];
            if (this.form.cups.search.length > 0 && this.form.cups.search.toString().replace(/ /g, "") != "") {
                this.form.cups.cups = await  this.$apiService.index("sistema/cups/buscar/" + this.form.cups.search.toUpperCase()).then(data =>  data); 
            }
            this.form.cups.isLoading = false;
        },
        async agregar() {
            this.form.coprId = this.coprId;
            this.$v.form.$touch();
            if (!this.$v.form.$error) {
                try {

                    if (this.isNew) {

                        let data = await this.$apiService.create(this.url + "create", this.form).then(data => data);
                        if (data) {
                            this.lista = await this.$apiService.index(this.url + "index/" + this.coprId).then(data => data);
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }

                    } else {
                        var id = this.form.cpesId;
                        let data = await this.$apiService.update(this.url + "update/" + id + "/", this.form).then(data => data);
                        if (data) {
                            this.lista = await this.$apiService.index(this.url + "index/" + this.coprId).then(data => data);
                            this.$swal.fire({
                                title: "Completado!",
                                text: "Registro exitoso",
                                icon: "success",
                                confirmButtonText: "Ok"
                            });
                        }

                    }
                    this.close();
                } catch (error) {
                    this.$swal.fire({
                        title: "Algo sucedio!",
                        text: "contacta con el administrador",
                        icon: "error"
                    });
                }
            }

        },
        async eliminar(id) {
            try {
                var result = await this.$swal.fire({
                    title: "Estas seguro?",
                    text: "No podras revertir esto!",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Si, eliminar!"
                }).then(result => result.value);
                if (result) {
                    let data = await this.$apiService.delete(this.url + "delete/" + id + "/").then(data => data);
                    if (data) {
                        this.lista = await this.$apiService.index(this.url + "index/" + this.coprId).then(data => data);
                        this.$swal.fire(
                            "Eliminado!",
                            "ha sido eliminado con exito.",
                            "success"
                        );
                    }
                }
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },
        async editar(id) {
            try {
                this.form = await this.$apiService.index(this.url + "view/" + id).then(data => data);
                this.form = {...this.form,cups:{
                  search: null,
                  isLoading: false,
                  cups: (this.form.tblConfCup?[this.form.tblConfCup]:[])
                }};
                this.isNew = false;
            } catch (error) {
                this.$swal.fire({
                    title: "Algo sucedio!",
                    text: "contacta con el administrador",
                    icon: "error"
                });
            }

        },

        limpiar() {
            this.form = {
                cpesId: null,
                coprId: this.coprId,
                coseId: null,
                cocuId:null,
                coseTipoServicio:null,
                cpesTipoEstado: null,
                cups :{
                  search: null,
                  isLoading: false,
                  cups: []
                }
            };
        },
        close() {
            this.isNew = true;
            this.dialog = false;
            this.limpiar();
        }
    }
};
</script>

<style>
</style>
